import { stringifyQueryString } from '@kaliber/sanity-routing/queryString'

import { routeMap } from '/routeMap'
import { makeSlug } from '/machinery/makeSlug'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { EnterAnimation } from '/features/buildingBlocks/EnterAnimation'
import { ContainerSm, ContainerLg } from '/features//buildingBlocks/Container'
import { HeadingLg, HeadingXl } from '/features/buildingBlocks/Heading'
import { Tag } from '/features/buildingBlocks/Tag'
import { Icon } from '/features/buildingBlocks/Icon'
import { ButtonLinkWhite } from '/features/buildingBlocks/Button'

import styles from './VacanciesList.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'
import { ensureArray } from '/machinery/ensureArray'

export function VacanciesList({ jobs, title, button }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const { showButton, jobFilters } = button || {}
  const { jobArea: rawJobArea } = jobFilters || {}

  const jobArea = ensureArray(rawJobArea)

  const href = jobArea.length
    ? `${routeMap.app.jobs({ language })}?${stringifyQueryString({ jobArea })}`
    : `${routeMap.app.jobs({ language })}`

  return (
    <div className={styles.component}>
      <VacanciesListBase
        dataX='vacancies-list'
        className={styles.vacanciesList}
        {...{ jobs, href, button }}
        renderHeadingComponent={() => <TitleLg {...{ title }} />}
        renderVacancyListItem={({ job }, idx) => <VacancyListItem key={idx} layoutClassName={styles.vacancyListItemLayout} {...{ job, showButton }} />}
      >
        {showButton && <ButtonLinkWhite dataX='link-to-jobs' label={__`all-jobs`} layoutClassName={styles.buttonLayout} {...{ href }} />}
      </VacanciesListBase>
    </div>
  )
}

export function VacanciesListRelated({ jobs, ATSJobAreaID = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const title = __`open-vacancies`

  const href = ATSJobAreaID
    ? `${routeMap.app.jobs({ language })}?${stringifyQueryString({ jobArea: [ATSJobAreaID] })}`
    : `${routeMap.app.jobs({ language })}`

  return (
    <VacanciesListBase
      dataX='related-vacancies'
      {...{ jobs, href }}
      renderHeadingComponent={() => <TitleXl {...{ title }} />}
      renderVacancyListItem={({ job }, idx) => <VacancyListItem key={idx} layoutClassName={styles.vacancyListItemLayout} showButton {...{ job }} />}
    >
      <ButtonLinkWhite dataX='link-to-jobs' label={__`all-jobs`} layoutClassName={styles.buttonLayout} {...{ href }} />
    </VacanciesListBase>
  )
}

function VacanciesListBase({ jobs, dataX, renderHeadingComponent, renderVacancyListItem, children = undefined, className = undefined }) {
  return (
    <section data-x={dataX} className={cx(styles.componentBase, className)}>
      {renderHeadingComponent()}

      <ContainerSm>
        <div className={styles.vacancyListContainer}>
          <ul className={styles.vacancyList}>
            {jobs.slice(0, 3).map(({ _source: job }, idx) => (
              renderVacancyListItem({ job }, idx)
            ))}
          </ul>
          {children }
        </div>
      </ContainerSm>
    </section>
  )
}

function VacancyListItem({ job, showButton = undefined, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  const { jobArea, title, id, hoursPerWeek, kind } = job
  const { name: jobAreaName } = jobArea
  const { label: hoursPerWeekLabel } = hoursPerWeek

  const href = kind === 'INTERNAL'
    ? routeMap.app.jobs.internalDetail({ language, jobTitle: makeSlug({ input: title, language }), jobId: id })
    : routeMap.app.jobs.detail({ language, jobTitle: makeSlug({ input: title, language }), jobId: id })

  return (
    <li className={cx(styles.componentVacancyListItem, showButton && styles.showButton, layoutClassName)}>
      <div className={styles.contentContainer}>
        <a data-x='link-in-related-vacancies-list' className={styles.jobAnchor} {...{ href }}>
          <h4 data-content={title} className={styles.cardTitle}>
            {title}
          </h4>
        </a>
        {(jobAreaName || hoursPerWeekLabel) && (
          <div className={styles.tagsContainer}>
            {jobAreaName && <Tag label={jobAreaName} colorScheme='white' layoutClassName={styles.tagLayout} />}
            {hoursPerWeekLabel && <Tag label={`${hoursPerWeekLabel} ${__`hours`}`} colorScheme='white' layoutClassName={styles.tagLayout} />}
          </div>
        )}
      </div>
      <div className={styles.arrowContainer}>
        <Icon icon={arrowIcon} />
      </div>
    </li>
  )
}

function TitleLg({ title }) {
  return (
    <ContainerSm>
      <EnterAnimation offset={-0.15}>
        <div className={styles.componentTitleLg}>
          <HeadingLg h='3'>{title}</HeadingLg>
        </div>
      </EnterAnimation>
    </ContainerSm>
  )
}

function TitleXl({ title }) {
  return (
    <ContainerLg>
      <EnterAnimation offset={-0.15}>
        <div className={styles.componentTitleXl}>
          <HeadingXl h='3'>{title}</HeadingXl>
        </div>
      </EnterAnimation>
    </ContainerLg>
  )
}
